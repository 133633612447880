import { isEqual, orderBy } from "lodash";
import React, { useState } from "react";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Card,
  Form,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import { RiChatCheckLine, RiFeedbackLine } from "react-icons/ri";
import { useQuery } from "react-query";
import styled, { css } from "styled-components";
import { getPrediction, postFeedback } from "../api";
import Loader from "./Loader";
import useConfig from "../hooks/useConfig";

type ClassificationModalProps = {
  features: Record<string, number | null>;
  onClose: () => void;
};

function ClassificationModal({ features, onClose }: ClassificationModalProps) {
  const config = useConfig();

  const [loading, setLoading] = useState(false);
  const [opinion, setOpinion] = useState<Record<string, string>>({});
  const [feedback, setFeedback] = useState<Record<string, string>>({});
  const [done, setDone] = useState<Record<string, boolean>>({});

  const { data: classification = [], status } = useQuery(
    ["get-attributes", { features, config }],
    (_, { config, features }) => getPrediction({ ...config, attr: features })
  );

  const onSend = async () => {
    setLoading(true);
    const promises = Object.entries(opinion).map(([clshash, opinion]) => {
      return postFeedback({
        clshash,
        opinion,
        feedback: opinion === "4" ? feedback[clshash] : undefined,
      }).then(() => {
        setDone((existing) => ({ ...existing, [clshash]: true }));
      });
    });

    await Promise.all(promises);
    setLoading(false);
  };

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Classificadores</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === "loading" ? (
          <Loader />
        ) : (
          <Accordion defaultActiveKey="0">
            {classification.map(({ title, classes, clshash }, index) => {
              const sortedClasses = orderBy(classes, "likelihood", "desc");
              const predicted = sortedClasses[0];

              return (
                <Card key={title}>
                  <Accordion.Toggle as={Card.Header} eventKey={`${index}`}>
                    {title} <Badge variant="success">{predicted.key}</Badge>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${index}`}>
                    <Card.Body>
                      {sortedClasses.map(({ key, likelihood }) => {
                        const percentage = likelihood * 100;

                        return (
                          <ProgressBarWrapper>
                            <p>
                              {key}{" "}
                              <strong>{formatPercentage(percentage)}</strong>
                            </p>
                            <ProgressBar
                              now={percentage}
                              label={formatPercentage(percentage)}
                              srOnly
                            />
                          </ProgressBarWrapper>
                        );
                      })}
                      <FeedbackForm isDone={!!done[clshash]}>
                        <fieldset>
                          <Form.Group>
                            <Form.Label as="legend">
                              <RiFeedbackLine /> O que você achou dessa
                              predição?
                            </Form.Label>
                            <Form.Check
                              readOnly={!!done[clshash]}
                              checked={opinion[clshash] === "1"}
                              onChange={() =>
                                setOpinion({ ...opinion, [clshash]: "1" })
                              }
                              type="radio"
                              label="A chance de óbito parece muito baixa para meu paciente"
                              name={`option--${clshash}`}
                              id={`opinion--${clshash}--1`}
                            />
                            <Form.Check
                              readOnly={!!done[clshash]}
                              checked={opinion[clshash] === "2"}
                              onChange={() =>
                                setOpinion({ ...opinion, [clshash]: "2" })
                              }
                              type="radio"
                              label="A chance de óbito parece adequada para meu paciente"
                              name={`option--${clshash}`}
                              id={`opinion--${clshash}--2`}
                            />
                            <Form.Check
                              readOnly={!!done[clshash]}
                              checked={opinion[clshash] === "3"}
                              onChange={() =>
                                setOpinion({ ...opinion, [clshash]: "3" })
                              }
                              type="radio"
                              label="A chance de óbito parece muito alta para meu paciente"
                              name={`option--${clshash}`}
                              id={`opinion--${clshash}--3`}
                            />
                            <Form.Check
                              readOnly={!!done[clshash]}
                              checked={opinion[clshash] === "4"}
                              onChange={() =>
                                setOpinion({ ...opinion, [clshash]: "4" })
                              }
                              type="radio"
                              label="Tenho outra observação/comentário"
                              name={`option--${clshash}`}
                              id={`opinion--${clshash}--4`}
                            />
                          </Form.Group>
                          <Form.Group
                            data-disabled={opinion[clshash] !== "4"}
                            controlId={`feedback--${clshash}`}
                          >
                            <Form.Control
                              as="textarea"
                              readOnly={!!done[clshash]}
                              rows={3}
                              value={feedback[clshash]}
                              onChange={({ target: { value } }) =>
                                setFeedback({ ...feedback, [clshash]: value })
                              }
                              placeholder="Descreva sua observação/comentário"
                            />
                          </Form.Group>
                        </fieldset>
                        {done[clshash] && (
                          <FeedbackBanner variant="success">
                            <RiChatCheckLine /> Obrigado pelo feedback!
                          </FeedbackBanner>
                        )}
                      </FeedbackForm>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
        <Button
          variant="primary"
          onClick={() => onSend()}
          disabled={
            loading ||
            Object.keys(opinion).length === 0 ||
            isEqual(Object.keys(opinion).sort(), Object.keys(done).sort())
          }
        >
          Enviar feedback
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ClassificationModal;

const ProgressBarWrapper = styled.div`
  display: flex;
  > * {
    flex: 1;
  }
`;

const FeedbackForm = styled(Form)`
  color: #d4d4d4;
  position: relative;

  legend {
    font-size: 1.1rem;
  }

  .form-group {
    transition: all ease 0.3s;
    transform: translateY(0);
    opacity: 1;

    &[data-disabled="true"] {
      transform: translateY(-100%);
      opacity: 0;
      pointer-events: none;
      height: 0;
      margin: 0;
    }
  }

  ${(props) =>
    props.isDone &&
    css`
      input,
      textarea {
        pointer-events: none;
      }
    `}
`;

const FeedbackBanner = styled(Alert)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  svg {
    height: 3rem;
    width: 3rem;
    margin-bottom: 0.2rem;
  }
`;

function formatPercentage(n: number) {
  if (n < 1) {
    return "<1%";
  }

  if (n > 99 && n < 100) {
    return ">99%";
  }

  return `${Math.round(n)}%`;
}
