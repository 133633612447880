import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

function Loader() {
    return (
        <Wrapper>
            <Spinner animation="border" role="status"/>

            <p>Carregando...</p>
        </Wrapper>
    )
}

export default Loader

const Wrapper =styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-size: 1.2rem;
        margin: 1rem;
    }
`