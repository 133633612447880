import React, { useState } from "react";

export const ConfigContext = React.createContext({
  hash_pred: "f8934",
  user_id: "rjd9d",
});

function getInitialConfig() {
  const queryString = new URLSearchParams(window.location.search);

  return {
    hash_pred: queryString.get("hash_pred") || "f8934",
    user_id: queryString.get("user_id") || "rjd9d",
  };
}

function ConfigProvider({ children }: React.PropsWithChildren<{}>) {
  const [config] = useState(getInitialConfig);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}

export default ConfigProvider;
