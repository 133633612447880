const BASE_URL = "https://cidia.ufrgs.dev/ws-weka-1.0-SNAPSHOT/calculator/v1";

type BaseInput = {
  user_id?: string;
  hash_pred?: string;
};

type GetAttributesInput = BaseInput;

export type GetAttributesOutput = {
  attr: string;
  label: string;
  type: 0 | 1;
  values: {
    value: string;
    label: string;
  }[];
}[];

export async function getAttributes({
  user_id,
  hash_pred,
}: GetAttributesInput = {}) {
  const response = await fetch(`${BASE_URL}/weka/attrs`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id, hash_pred }),
  });

  const data = await response.json();

  return data.afs as GetAttributesOutput;
}

type GetPredictionInput = BaseInput & { attr: Record<string, number | null> };

export type GetPredictionOutput = {
  title: string;
  clshash: string;
  classes: { index: number; key: string; likelihood: number }[];
}[];

export async function getPrediction({
  user_id,
  hash_pred,
  attr,
}: GetPredictionInput) {
  const response = await fetch(`${BASE_URL}/weka/predict`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id, hash_pred, attr: JSON.stringify(attr) }),
  });

  const data = await response.json();

  return data.rcls as GetPredictionOutput;
}

type PostFeedbackInput = {
  clshash: string;
  opinion: string;
  feedback?: string;
};
export async function postFeedback(input: PostFeedbackInput) {
  return fetch(`${BASE_URL}/feedback/send`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(input),
  });
}
