import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getAttributes } from "./api";
import ClassificationModal from "./components/ClassificationModal";
import FeaturesForm from "./components/FeaturesForm";
import Loader from "./components/Loader";
import useConfig from "./hooks/useConfig";

import UFRGSLogo from "./assets/ufrgs.svg";
import INFLogo from "./assets/inf.svg";
import UnicampLogo from "./assets/uec.svg";
import HMVLogo from "./assets/hmv.svg";
import HCPALogo from "./assets/hcpa.svg";

import { ReactComponent as FixingBugsIllustration } from "./assets/chat.svg";

function App() {
  const config = useConfig();

  const { data: attributes = [], status } = useQuery(
    ["get-attributes", {config}],
    (_, {config}) => getAttributes(config)
  );

  const [features, setFeatures] = useState<Record<
    string,
    number | null
  > | null>(null);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const target = event.target as any;

    setFeatures(
      attributes.reduce<Record<string, number | null>>((features, { attr }) => {
        const element = target.elements[attr];

        if (element.type === "checkbox") {
          features[attr] = element.checked ? 1 : 0;
        } else {
          const value = element.value;
          features[attr] = value ? Number(value) : null;
        }
        return features;
      }, {})
    );
  };

  return (
    <div>
      <Header>
        <Container>
          <h1>
            CIDIA Classificador{" "}
            <div>
              {[
                [UFRGSLogo, "UFRGS"],
                [INFLogo, "Instituto de Informática"],
                [UnicampLogo, "Unicamp"],
                [HMVLogo, "Hospital Moinhos de Vento"],
                [HCPALogo, "Hospital de Clínicas de Porto Alegre"],
              ].map(([logo, label]) => (
                <img key={logo} src={logo} alt={label} />
              ))}
            </div>
          </h1>
        </Container>
      </Header>
      <Container>
        {status === "loading" && <Loader />}
        {status === "error" && (
          <ErrorContainer>
            <FixingBugsIllustration />

            <p>
              Parece que algo está errado! Não se preocupe, nossa equipe já está
              trabalhando para consertar.
            </p>
            <p>Por favor, tente novamente daqui a alguns minutos.</p>
            <p>Obrigado pela compreensão!</p>
            <p>– Equipe CIDIA.</p>
          </ErrorContainer>
        )}
        {status === "success" && (
          <FeaturesForm attributes={attributes} onSubmit={handleSubmit} />
        )}
        {features && (
          <ClassificationModal
            features={features}
            onClose={() => setFeatures(null)}
          />
        )}
      </Container>
      <Footer>CIDIA &copy; 2020</Footer>
    </div>
  );
}

export default App;

const Header = styled.header`
  margin: 3rem 0;

  h1 {
    display: flex;
    justify-content: space-between;

    img {
      height: 55px;

      &:not(:first-child) {
        margin-left: 1rem;
      }
    }

    @media (max-width: 768px) {
      text-align: center;
      font-size: 2rem;
      flex-direction: column;

      img {
        height: 30px;
      }
    }
  }
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  margin: 3rem 1rem 2rem;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem;
  border-left: 5px solid red;
  background: white;
  color: #0c0c0c;
  border-radius: 4px;

  p {
    max-width: 90%;
    z-index: 1;
  }

  svg {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    height: 4rem;
    width: 4rem;
  }
`;
