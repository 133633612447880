import { startCase } from "lodash";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { GetAttributesOutput } from "../api";

type FeaturesFormProps = {
  onSubmit: (event: React.FormEvent) => void;
  attributes: GetAttributesOutput;
};

function FeaturesForm({ onSubmit, attributes }: FeaturesFormProps) {
  const categoricalFeatures = attributes.filter(({ type }) => type === 1);

  const continuousFeatures = attributes.filter(({ type }) => type === 0);

  return (
    <Form onSubmit={onSubmit}>
      {categoricalFeatures.map(({ attr, label, values }) => {
        const effectiveLabel = label ?? startCase(attr);

        return (
          <Form.Group key={attr} as={Row} controlId={attr}>
            <Form.Label column md="2">
              {effectiveLabel}
            </Form.Label>
            <Col md="10">
              <Form.Control as="select">
                <option>Ignorado</option>
                {values.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        );
      })}

      {continuousFeatures.map(({ attr, label }) => {
        const effectiveLabel = label ?? startCase(attr);

        return (
          <Form.Group key={attr} as={Row} controlId={attr}>
            <Form.Label column md="2">
              {effectiveLabel}
            </Form.Label>
            <Col md="10">
              <Form.Control type="number" step="any" />
            </Col>
          </Form.Group>
        );
      })}

      <ButtonContainer>
        <Button variant="secondary" type="reset">
          Limpar
        </Button>
        <Button variant="primary" type="submit">
          Classificar
        </Button>
      </ButtonContainer>
    </Form>
  );
}

export default FeaturesForm;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button + button {
    margin-left: 0.5rem;
  }
`;
